import Comp from '@/models/Comp'

export default {
  props: {
    comp: { type: Comp, default: null },
    params: { type: Object, default: () => ({}) },
    isEditor: { type: Boolean, default: false },
  },
  methods: {
    setParam(key, val) {
      if (!this.isEditor) return
      let paramsLocal = { ...this.params }
      paramsLocal[key] = val
      this.$emit('params-change', paramsLocal)
    },
  },
}
