<template>
  <div>
    <p>{{ $t('competitions.info.divBy.description') }}</p>
    <ul class="mb-4">
      <li v-if="params.bonus_percent_fl > 0">
        <span>{{ $t('climbs.log.flash') }}: </span>
        <span>{{ $t('competitions.info.divBy.bonusPercent', { percentage: params.bonus_percent_fl }) }}</span>
      </li>
      <li v-if="params.bonus_percent_os > 0">
        <span>{{ $t('climbs.log.onsight') }}: </span>
        <span>{{ $t('competitions.info.divBy.bonusPercent', { percentage: params.bonus_percent_os }) }}</span>
      </li>
    </ul>

    <div v-if="isEditor">
      <div class="title">Configuration</div>
      <v-text-field
        :value="params.bonus_percent_fl"
        type="number"
        label="% bonus points for flash"
        @input="setParam('bonus_percent_fl', $event)"
      />
      <v-text-field
        :value="params.bonus_percent_os"
        type="number"
        label="% bonus points for on-sight"
        @input="setParam('bonus_percent_os', $event)"
      />
    </div>
  </div>
</template>

<script>
import scoreSystemMixin from './score-system.mixin'

export default {
  mixins: [scoreSystemMixin],
}
</script>
